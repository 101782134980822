// React
import ReactDOM from "react-dom/client";
// Routes
import RoutesTree from "./routes/RoutesTree";
// Redux
import { Provider } from "react-redux";
// Components
import LoadingContent from "./components/ui/LoadingContent";
import AppNetwork from "./components/app/AppNetwork";
import StoreLoading from "./components/store/StoreLoading";
import StoreModal from "./components/store/StoreModal";
import StoreNotification from "./components/store/StoreNotification";
// Redux-persist
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// Store
import { setupStore } from "./store";
import { initialStore } from "./store/types";
// i18n
import "./locales";
// Archivo tailwind generado
import "./App.css";
// store and persist
const store = setupStore(initialStore);
const persistor = persistStore(store);

// Load Root DOM
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingContent />} persistor={persistor}>
      {/* Network */}
      <AppNetwork />
      {/* Routes */}
      <RoutesTree />
      {/* Loading */}
      <StoreLoading />
      {/* Modal */}
      <StoreModal />
      {/* Notification */}
      <StoreNotification />
    </PersistGate>
  </Provider>,
);
