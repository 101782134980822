// ICONS-LOGOS
import spotifyIcon from "../assets/img/spotify_icon.png";
import spotifyLogo from "../assets/img/spotify_logo.png";
import youtubeIcon from "../assets/img/youtube_icon.png";
import youtubeLogo from "../assets/img/youtube_logo.png";
// REDUX
export const REDUX_KEY = "putsong";
// PROVIDERS ICONS
export const PROVIDERS_MEDIA = {
  spotify: {
    icon: spotifyIcon,
    logo: spotifyLogo,
  },
  youtube: {
    icon: youtubeIcon,
    logo: youtubeLogo,
  },
};
// USER AGENTS
export const USER_AGENTS = {
  webos: "Web0S",
};
