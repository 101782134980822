// Locales
import en from "./en.json";
import es from "./es.json";

export default {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};
