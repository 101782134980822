import { all } from "redux-saga/effects";
// Sagas
import {
  loginUserInServerTakeLatest,
  logoutUserInServerTakeLatest,
  refreshTokenInServerTakeEvery,
  setUserInServerTakeLatest,
} from "./user";

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga(): Generator<any> {
  yield all([
    loginUserInServerTakeLatest(),
    logoutUserInServerTakeLatest(),
    refreshTokenInServerTakeEvery(),
    setUserInServerTakeLatest(),
  ]);
}
