import React, { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import FormButtons from "./FormButtons";
import { isNil } from "lodash";

// Propiedas del componente
export interface ModalProps {
  onClickAcceptButton?: (event: React.SyntheticEvent) => void;
  acceptButtonLabel?: string;
  onClickCancelButton?: (event: React.SyntheticEvent) => void;
  cancelButtonLabel?: string;
  children: React.ReactNode;
  hideAcceptButton?: boolean;
  hideCancelButton?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  onChangeShow?: (show: boolean) => void;
  persistent?: boolean;
  show: boolean;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  onClickAcceptButton,
  acceptButtonLabel = "Aceptar",
  onClickCancelButton,
  cancelButtonLabel = "Cancelar",
  children = null,
  hideAcceptButton = false,
  hideCancelButton = false,
  hideFooter = false,
  hideHeader = false,
  onChangeShow,
  persistent = false,
  show = false,
  title = "Título de la modal",
}: ModalProps): JSX.Element => {
  // DATA

  // Se muestra/oculta la notificación
  const [currentShow, setCurrentShow] = useState(show);
  // Referencia al botón cancelar
  const cancelButtonRef = useRef(null);

  // EVENTS

  /**
   * Emitimos el evento de cambio de estado de "show"
   */
  useEffect(() => {
    if (!isNil(onChangeShow)) {
      onChangeShow(currentShow);
    }
  }, [currentShow]);

  /**
   * Modificamos el estado del "show"
   */
  useEffect(() => {
    setCurrentShow(show);
  }, [show]);

  // METHODS

  /**
   * Manejador de la acción adicional del click
   * en el botón "Aceptar"
   */
  function handleClickAcceptButtonAction(event: React.SyntheticEvent): void {
    if (!isNil(onClickAcceptButton)) {
      onClickAcceptButton(event);
    }

    // Además incluimos el cierre de la modal
    setCurrentShow(false);
  }

  /**
   * Manejador de la acción adicional del click
   * en el botón "Cancelar"
   */
  function handleClickCancelButtonAction(event: React.SyntheticEvent): void {
    if (!isNil(onClickCancelButton)) {
      onClickCancelButton(event);
    }

    // Además incluimos el cierre de la modal
    setCurrentShow(false);
  }

  return (
    <Transition.Root show={currentShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={!persistent ? setCurrentShow : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-start p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white drop-shadow-sm transition-all m-4 w-full max-w-lg">
                <div className="bg-white">
                  <div className="flex flex-col items-start justify-center">
                    {/* Title */}
                    {!hideHeader ? (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 p-4 w-full"
                      >
                        {title}
                      </Dialog.Title>
                    ) : null}
                    {/* Content */}
                    <div className="w-full">
                      {children !== null ? children : null}
                    </div>
                  </div>
                </div>
                {/* Action buttons */}
                {!hideFooter ? (
                  <div className="bg-gray-100 px-4 py-3 flex flex-row-reverse">
                    <FormButtons
                      acceptButtonClick={(event: React.SyntheticEvent) =>
                        handleClickAcceptButtonAction(event)
                      }
                      hideAcceptButton={hideAcceptButton}
                      acceptButtonLabel={acceptButtonLabel}
                      acceptButtonType="submit"
                      cancelButtonClick={(event: React.SyntheticEvent) =>
                        handleClickCancelButtonAction(event)
                      }
                      cancelButtonHide={hideCancelButton}
                      cancelButtonLabel={cancelButtonLabel}
                      cancelButtonType="button"
                    />
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
