// React
import React from "react";
import { Routes, Route } from "react-router-dom";
// Contants
import { USER_AGENTS } from "../constants";
// Components
import AppUrlListener from "../components/app/AppUrlListener";
import ProtectedRoute from "./ProtectedRoute";
import LoadingContent from "../components/ui/LoadingContent";
// Routes
import CustomRoutes from "../router/CustomRoutes";
import history from "../router/history";
// Redux
import { useSelector } from "react-redux";
// Store
import { StoreState } from "../store/types";
// Contenedor dependencias
import container from "../../modules/Shared/container";
// Libs
import { get } from "lodash";
// Utils
import { getNavigatorUserAgent } from "../../modules/Shared/utils";

// Routes
const LazyProvidersList = React.lazy(
  async () => await import("./providers/ProvidersList"),
);
const LazyProvidersSmartTvList = React.lazy(
  async () => await import("./providers/ProvidersSmartTvList"),
);
const LazyProvidersDetail = React.lazy(
  async () => await import("./providers/ProvidersDetail"),
);
const LazyUsersProfile = React.lazy(
  async () => await import("./users/UsersProfile"),
);
const LazyProvidersPlayBackQueue = React.lazy(
  async () => await import("./providers/ProvidersPlayBackQueue"),
);
const LazyProvidersSpotifyCallback = React.lazy(
  async () => await import("./providers/ProvidersSpotifyCallback"),
);
const LazyProvidersYoutubeCallback = React.lazy(
  async () => await import("./providers/ProvidersYoutubeCallback"),
);
const LazyAuthLogin = React.lazy(async () => await import("./auth/AuthLogin"));
const LazyAuthSmartTvLogin = React.lazy(
  async () => await import("./auth/AuthSmartTvLogin"),
);
const LazyAuthRecoverPassword = React.lazy(
  async () => await import("./auth/AuthRecoverPassword"),
);
const LazyAuthRegister = React.lazy(
  async () => await import("./auth/AuthRegister"),
);
const LazyAuthResetPassword = React.lazy(
  async () => await import("./auth/AuthResetPassword"),
);
const LazyAuthLogout = React.lazy(
  async () => await import("./auth/AuthLogout"),
);

const RoutesTree: React.FC = (): JSX.Element => {
  // Login Path
  const loginPath = "/login";
  // State
  const user = useSelector((state: StoreState) => state.user);

  return (
    <CustomRoutes history={history}>
      {/* To Deep Links */}
      <AppUrlListener />
      {/* Router */}
      <Routes>
        {/* Auth */}
        <Route
          path={loginPath}
          element={
            <React.Suspense fallback={<LoadingContent />}>
              {getNavigatorUserAgent() === USER_AGENTS.webos ? (
                <LazyAuthSmartTvLogin />
              ) : (
                <LazyAuthLogin />
              )}
            </React.Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <ProtectedRoute
              redirect={loginPath}
              isAllowed={get(user, "id", null) !== null}
            >
              <React.Suspense fallback={<LoadingContent />}>
                <LazyAuthLogout />
              </React.Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path="/register"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyAuthRegister
                authRepository={container.resolve("authRepository")}
              />
            </React.Suspense>
          }
        />
        <Route
          path="/recover"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyAuthRecoverPassword
                authRepository={container.resolve("authRepository")}
              />
            </React.Suspense>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyAuthResetPassword
                authRepository={container.resolve("authRepository")}
              />
            </React.Suspense>
          }
        />
        {/* Providers */}
        <Route
          path="/"
          element={
            <ProtectedRoute
              redirect={loginPath}
              isAllowed={get(user, "id", null) !== null}
            >
              <React.Suspense fallback={<LoadingContent />}>
                {getNavigatorUserAgent() === USER_AGENTS.webos ? (
                  <LazyProvidersSmartTvList
                    authRepository={container.resolve("authRepository")}
                  />
                ) : (
                  <LazyProvidersList
                    authRepository={container.resolve("authRepository")}
                  />
                )}
              </React.Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path="/providers/:providerId"
          element={
            <ProtectedRoute
              redirect={loginPath}
              isAllowed={get(user, "id", null) !== null}
            >
              <React.Suspense fallback={<LoadingContent />}>
                <LazyProvidersDetail
                  providerRepository={container.resolve("providerRepository")}
                />
              </React.Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          path="/providers/playback-queue/:providerId/:player?"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <React.Suspense fallback={<LoadingContent />}>
                <LazyProvidersPlayBackQueue
                  authRepository={container.resolve("authRepository")}
                  providerRepository={container.resolve("providerRepository")}
                />
              </React.Suspense>
            </React.Suspense>
          }
        />
        <Route
          path="/spotify-callback"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyProvidersSpotifyCallback
                providerRepository={container.resolve("providerRepository")}
              />
            </React.Suspense>
          }
        />
        <Route
          path="/youtube-callback"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyProvidersYoutubeCallback
                providerRepository={container.resolve("providerRepository")}
              />
            </React.Suspense>
          }
        />
        {/* User */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              redirect={loginPath}
              isAllowed={get(user, "id", null) !== null}
            >
              <React.Suspense fallback={<LoadingContent />}>
                <LazyUsersProfile
                  authRepository={container.resolve("authRepository")}
                />
              </React.Suspense>
            </ProtectedRoute>
          }
        />
        {/* Other */}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<LoadingContent />}>
              <LazyAuthLogout />
            </React.Suspense>
          }
        />
      </Routes>
    </CustomRoutes>
  );
};

export default RoutesTree;
