import {
  LoginUserInServerAction,
  LogoutUserInServerAction,
  RefreshTokenInServerAction,
  ResetUserAction,
  SetUserAction,
  SetUserInServerAction,
  StoreUser,
  StoreUserTypes,
} from "./types";

/**
 * Logamos usuario en servidor
 */
export function loginUserInServer(payload: {
  email: string;
  password: string;
}): LoginUserInServerAction {
  return {
    type: StoreUserTypes.LOGIN_USER_IN_SERVER,
    payload,
  };
}

/**
 * Des-Logamos usuario en servidor
 */
export function logoutUserInServer(payload: {
  refreshToken: string;
}): LogoutUserInServerAction {
  return {
    type: StoreUserTypes.LOGOUT_USER_IN_SERVER,
    payload,
  };
}

/**
 * Refrescamos token del usuario en servidor
 */
export function refreshTokenInServer(payload: {
  refreshToken: string;
}): RefreshTokenInServerAction {
  return {
    type: StoreUserTypes.REFRESH_TOKEN_IN_SERVER,
    payload,
  };
}

/**
 * Reseteamos valores del usuario en local
 */
export function resetUser(): ResetUserAction {
  return {
    type: StoreUserTypes.RESET_USER,
  };
}

/**
 * Establecemos valores del usuario en local
 */
export function setUser(payload: StoreUser): SetUserAction {
  return {
    type: StoreUserTypes.SET_USER,
    payload,
  };
}

/**
 * Establecemos valores del usuario en el servidor y local
 */
export function setUserInServer(payload: StoreUser): SetUserInServerAction {
  return {
    type: StoreUserTypes.SET_USER_IN_SERVER,
    payload,
  };
}
