import {
  initialStoreRefreshToken,
  StoreRefreshToken,
  StoreRefreshTokenTypes,
  StoreRefreshTokenActions,
} from "./types";

const storeRefreshTokenReducers = (
  state: StoreRefreshToken = initialStoreRefreshToken,
  action: StoreRefreshTokenActions,
): StoreRefreshToken => {
  switch (action.type) {
    case StoreRefreshTokenTypes.SET_REFRESHTOKEN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default storeRefreshTokenReducers;
