export enum StoreModalTypes {
  RESET_MODAL = "RESET_MODAL",
  SET_MODAL = "SET_MODAL",
}

export interface StoreModalActions {
  type: StoreModalTypes;
  payload?: StoreModal;
}

export interface StoreModal {
  onClickAcceptButton?: () => void;
  acceptButtonLabel: string;
  onClickCancelButton?: () => void;
  cancelButtonLabel: string;
  content?: any;
  contentProps: object;
  hideAcceptButton: boolean;
  hideCancelButton: boolean;
  hideFooter: boolean;
  hideHeader: boolean;
  persistent: boolean;
  show: boolean;
  title: string;
}

export const initialStoreModal: StoreModal = {
  onClickAcceptButton: undefined,
  acceptButtonLabel: "Aceptar",
  onClickCancelButton: undefined,
  cancelButtonLabel: "Cancelar",
  content: undefined,
  contentProps: {},
  hideAcceptButton: false,
  hideCancelButton: false,
  hideFooter: false,
  hideHeader: false,
  persistent: false,
  show: false,
  title: "Título de la modal",
};
