import {
  StoreNotification,
  StoreNotificationActions,
  StoreNotificationTypes,
} from "./types";

/**
 * Reset store's notification
 */
export function resetNotification(): StoreNotificationActions {
  return {
    type: StoreNotificationTypes.RESET_NOTIFICATION,
  };
}

/**
 * Set parameters in store's notification
 */
export function setNotification(
  payload: StoreNotification,
): StoreNotificationActions {
  return {
    type: StoreNotificationTypes.SET_NOTIFICATION,
    payload,
  };
}
