import { APP_URL, SPOTIFY_CLIENT_ID } from "../../Shared/config";

export interface GetAuthorizeUrlParams {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  show_dialog: string;
}

export default class SpotifyProvider {
  private readonly clientId: string = SPOTIFY_CLIENT_ID;

  /**
   * Get authorize from provider
   */
  getAuthorizeUrl(params?: object): string {
    const defaultParams = {
      client_id: this.clientId,
      redirect_uri: `${APP_URL}/spotify-callback`,
      response_type: "code",
      scope:
        "user-read-currently-playing user-read-playback-state user-modify-playback-state",
      show_dialog: "false",
    };

    const urlParams = new URLSearchParams({ ...defaultParams, ...params });

    return `https://accounts.spotify.com/authorize?${urlParams.toString()}`;
  }
}
