// React
import React, { useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// Store
import { StoreState } from "../../store/types";
// Hooks
import useActions from "../../hooks/UseActions";
// Components
import Modal from "../ui/Modal";
// Actions
import * as ModalActions from "../../store/modal/actions";
// Libs
import { isNil } from "lodash";

const StoreModal: React.FC = (): JSX.Element => {
  // State
  const modal = useSelector((state: StoreState) => state.modal);
  // Actions
  const modalActions = useActions(ModalActions);

  // EVENTS

  useEffect(() => {
    // reseteamos en la carga del componente
    modalActions.resetModal();
  }, []);

  // METHODS

  /**
   * Manejamos el cambio de visualización de la modal
   */
  function handleChangeShow(show: boolean): void {
    if (!show) {
      // Ocultamos modal
      modalActions.setModal({ show });
      // Retraso N segundos para que no se vea el cambio
      // en el efecto CSS de ocultación
      setTimeout(() => {
        modalActions.resetModal();
      }, 500);
    }
  }

  return (
    <Modal
      onClickAcceptButton={modal.onClickAcceptButton}
      acceptButtonLabel={modal.acceptButtonLabel}
      onClickCancelButton={modal.onClickCancelButton}
      cancelButtonLabel={modal.cancelButtonLabel}
      hideAcceptButton={modal.hideAcceptButton}
      hideCancelButton={modal.hideCancelButton}
      hideFooter={modal.hideFooter}
      hideHeader={modal.hideHeader}
      onChangeShow={handleChangeShow}
      persistent={modal.persistent}
      show={modal.show}
      title={modal.title}
    >
      {!isNil(modal.content) ? (
        <modal.content
          {...(!isNil(modal.contentProps) &&
          typeof modal.contentProps === "object" &&
          Object.keys(modal.contentProps).length > 0
            ? modal.contentProps
            : {})}
        />
      ) : null}
    </Modal>
  );
};

export default StoreModal;
