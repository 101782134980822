import User from "../../../modules/Auth/domain/User";

export enum StoreUserTypes {
  LOGIN_USER_IN_SERVER = "LOGIN_USER_IN_SERVER",
  LOGOUT_USER_IN_SERVER = "LOGOUT_USER_IN_SERVER",
  REFRESH_TOKEN_IN_SERVER = "REFRESH_TOKEN_IN_SERVER",
  RESET_USER = "RESET_USER",
  SET_USER = "SET_USER",
  SET_USER_IN_SERVER = "SET_USER_IN_SERVER",
}

export interface LoginUserInServerAction {
  type: StoreUserTypes;
  payload: { email: string; password: string };
}

export interface LogoutUserInServerAction {
  type: StoreUserTypes;
  payload: { refreshToken: string };
}

export interface RefreshTokenInServerAction {
  type: StoreUserTypes;
  payload: { refreshToken: string };
}

export interface ResetUserAction {
  type: StoreUserTypes;
}

export interface SetUserAction {
  type: StoreUserTypes;
  payload: StoreUser;
}

export interface SetUserInServerAction {
  type: StoreUserTypes;
  payload: StoreUser;
}

export interface StoreUser extends Partial<Omit<User, "password">> {}

export const initialStoreUser: StoreUser = {
  id: undefined,
  email: undefined,
  refreshToken: undefined,
  token: undefined,
};
