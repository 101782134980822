import {
  initialStoreUser,
  StoreUser,
  StoreUserTypes,
  ResetUserAction,
  SetUserAction,
} from "./types";

const userReducer = (
  state: StoreUser = initialStoreUser,
  action: ResetUserAction | SetUserAction,
): StoreUser => {
  switch (action.type) {
    case StoreUserTypes.RESET_USER: {
      return { ...initialStoreUser };
    }
    case StoreUserTypes.SET_USER: {
      return { ...state, ...("payload" in action ? action.payload : {}) };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
