// React
import React, { useEffect } from "react";
// Constants
import { APP_URL, ANDROID_PROTOCOL } from "../../../modules/Shared/config";
// Router
import { useNavigate } from "react-router-dom";
// Actions
import * as NotificationActions from "../../store/notification/actions";
// Hooks
import useActions from "../../hooks/UseActions";
// Capacitor
import { App, URLOpenListenerEvent } from "@capacitor/app";
// Libs
import { isNil } from "lodash";

const AppUrlListener: React.FC = (): null => {
  // Route
  const navigate = useNavigate();
  // Actions
  const notificationActions = useActions(NotificationActions);

  // EVENTS

  useEffect(() => {
    App.addListener("appUrlOpen", handleAppUrlOpen).catch((err: any) =>
      handleError(err.message),
    );
    return () => {
      App.removeAllListeners().catch((err: any) => handleError(err.message));
    };
  }, []);

  // METHODS

  function handleError(error: string): void {
    notificationActions.setNotification({
      show: true,
      text: error,
    });
  }

  /**
   * "Manejador" de la escucha de las URL
   */
  function handleAppUrlOpen(event: URLOpenListenerEvent): void {
    const nativeApp = event.url.includes(ANDROID_PROTOCOL);
    const webApp = event.url.includes(APP_URL);
    let slug = "";

    if (nativeApp) {
      slug = event.url.replace(`${ANDROID_PROTOCOL}://`, "/");
    }

    if (webApp) {
      slug = event.url.replace(`${APP_URL}`, "");
    }

    if (!isNil(slug)) {
      navigate(slug);
    }
  }

  return null;
};

export default AppUrlListener;
