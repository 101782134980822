export enum StoreRefreshTokenTypes {
  SET_REFRESHTOKEN = "SET_REFRESHTOKEN",
}

export interface StoreRefreshTokenActions {
  type: StoreRefreshTokenTypes;
  payload?: StoreRefreshToken;
}

export interface StoreRefreshToken {
  loading: boolean;
}

export const initialStoreRefreshToken: StoreRefreshToken = {
  loading: false,
};
