// React
import React, { useState, useLayoutEffect } from "react";
import { Router } from "react-router-dom";
// History
import { BrowserHistory } from "history";
// Propiedas del componente
export interface CustomRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: BrowserHistory;
}

const CustomRouter: React.FC<CustomRouterProps> = ({
  basename = "/",
  children = null,
  history,
}: CustomRouterProps): JSX.Element => {
  // DATA

  // Estado de la navegación
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  // EVENTS

  useLayoutEffect(() => {
    history.listen(setState);
  }, [history]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

export default CustomRouter;
