import React from "react";
import { isNil, get } from "lodash";
import { useTranslation } from "react-i18next";
import { setClassNames } from "../../../modules/Shared/utils";

// Propiedas del componente
export interface LoadingProps {
  border?: number;
  color?: string;
  size?: number;
  text?: string;
}

const Loading: React.FC<LoadingProps> = ({
  border = 2,
  color = "primary",
  size = 10,
  text,
}: LoadingProps): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  /**
   * Clases CSS para el SPINNER
   */
  function spinnerClassName(): string {
    const defaultClasses = "inline-block animate-spin rounded-full";
    const borderClasses = {
      0: "border-0",
      2: "border-2",
      4: "border-4",
      8: "border-8",
    };
    const colorClasses = {
      primary: "border-t-primary",
      secondary: "border-t-secondary",
    };
    const sizeClasses = {
      8: "h-8 w-8",
      10: "h-10 w-10",
      12: "h-12 w-12",
      14: "h-14 w-14",
    };

    const currentBorderClasses = get(borderClasses, border, "border");
    const currentColorClasses = get(
      colorClasses,
      color,
      "border-t-primary-500",
    );
    const currentSizeClasses = get(sizeClasses, size, "h-8 w-8");

    return setClassNames(
      defaultClasses,
      currentBorderClasses,
      currentColorClasses,
      currentSizeClasses,
    );
  }

  return (
    <>
      {/* Loading */}
      <div className="flex items-center justify-center">
        <div className={spinnerClassName()}></div>
      </div>
      {/* Label */}
      <span className="mt-2 block text-sm font-medium text-gray-500">
        {isNil(text) ? t("components.loading.text") : text}
      </span>
    </>
  );
};

export default Loading;
