// React
import React from "react";
// Components
import Button from "../../components/ui/Button";
// Icons
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
// Redux
import { useSelector } from "react-redux";
// Store
import { StoreState } from "../../store/types";
import * as UserActions from "../../store/user/actions";
import * as ModalActions from "../../store/modal/actions";
// Hooks
import useActions from "../../hooks/UseActions";
// i18n
import { useTranslation } from "react-i18next";

// Propiedas del componente
export interface AppErrorProps {
  error: string;
}

/**
 * Componente que muestra un error fatal de la aplicación
 */
const AppError: React.FC<AppErrorProps> = ({
  error = "Error",
}): JSX.Element => {
  // Actions
  const userActions = useActions(UserActions);
  const modalActions = useActions(ModalActions);
  // State
  const user = useSelector((state: StoreState) => state.user);
  // i18n
  const { t } = useTranslation();

  // METHODS

  /**
   * "Manejador" click del botón
   */
  function handleClickButton(): void {
    // Close modal
    modalActions.setModal({
      show: false,
    });
    // Logout
    userActions.logoutUserInServer({
      refreshToken: user.refreshToken,
    });
  }

  return (
    <div className="flex flex-col items-center justify-start p-4">
      {/* Icon */}
      <ExclamationTriangleIcon
        className="h-24 w-24 text-red-500 mb-2"
        aria-hidden="true"
      />
      {/* Text */}
      <p className="text-lg text-gray-900 font-medium mb-2">
        {t("app.error.title")}
      </p>
      <p className="text-sm text-gray-500 font-medium mb-8">{error}</p>
      <Button
        type="button"
        label={t("app.error.button")}
        onClick={() => handleClickButton()}
      />
    </div>
  );
};

export default AppError;
