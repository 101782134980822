import { v4 as uuidv4 } from "uuid";
import { isNil, capitalize } from "lodash";
import Provider from "../domain/Provider";
import Song from "../../Songs/domain/Song";
import type ProviderRepository from "../domain/ProviderRepository";
import Http, {
  HttpOptions,
  HttpMethod,
} from "../../Shared/infrastructure/Http";

export default class ApiProviderRepository
  extends Http
  implements ProviderRepository
{
  public apiUrl: string = "/";

  constructor(props = { apiUrl: "/" }) {
    super();
    this.apiUrl = props.apiUrl;
  }

  /**
   * Create new provider
   */
  async createProvider(
    data: Provider,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    const { id = null, ...params } = data;

    await this.request(`${this.apiUrl}/providers/create`, {
      method: HttpMethod.POST,
      body: { ...params, id: isNil(id) ? uuidv4() : id },
      ...options,
    });
  }

  /**
   * Find provider by id
   */
  async findProviderById(
    id: string,
    options: Partial<HttpOptions>,
  ): Promise<Provider | null> {
    const provider = await this.request(`${this.apiUrl}/providers/${id}`, {
      method: HttpMethod.GET,
      ...options,
    });

    return !isNil(provider)
      ? { ...provider, label: capitalize(provider.type) }
      : null;
  }

  /**
   * Update provider
   */
  async updateProvider(
    data: Partial<Provider> & { id: string },
    options: Partial<HttpOptions>,
  ): Promise<void> {
    const { id, ...params } = data;
    await this.request(`${this.apiUrl}/providers/${id}`, {
      method: HttpMethod.PATCH,
      body: params,
      ...options,
    });
  }

  /**
   * Delete provider
   */
  async deleteProvider(
    id: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/providers/${id}`, {
      method: HttpMethod.DELETE,
      ...options,
    });
  }

  /**
   * Get playback queue
   */
  async getPlaybackQueue(
    id: string,
    options: Partial<HttpOptions>,
  ): Promise<Song[] | []> {
    const songs = await this.request(
      `${this.apiUrl}/providers/${id}/playback-queue`,
      { method: HttpMethod.GET, ...options },
    );

    return songs;
  }

  /**
   * Add song to playback queue
   */
  async addSongToPlaybackQueue(
    providerId: string,
    song: Song,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(
      `${this.apiUrl}/providers/${providerId}/playback-queue`,
      { method: HttpMethod.POST, body: song, ...options },
    );
  }

  /**
   * Remove song from playback queue
   */
  async removeSongFromPlaybackQueue(
    providerId: string,
    songId: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(
      `${this.apiUrl}/providers/${providerId}/playback-queue/${songId}`,
      { method: HttpMethod.DELETE, ...options },
    );
  }

  /**
   * Search songs
   */
  async searchSongs(
    id: string,
    query: string,
    options: Partial<HttpOptions>,
  ): Promise<Song[] | []> {
    const songs = await this.request(
      `${this.apiUrl}/providers/${id}/search?query=${query}`,
      { method: HttpMethod.GET, ...options },
    );

    return songs;
  }
}
