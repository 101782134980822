// React
import React, { useEffect } from "react";
// Store
import * as ModalActions from "../../store/modal/actions";
import * as NotificationActions from "../../store/notification/actions";
// Components
import Button from "../ui/Button";
// Hooks
import useActions from "../../hooks/UseActions";
// Libs
import { get } from "lodash";
// Icons
import { WifiIcon } from "@heroicons/react/24/outline";
// Capacitor
import { Network } from "@capacitor/network";
// i18n
import { useTranslation } from "react-i18next";

/**
 * Componente que muestra la información de la falta
 * de conexión en la modal
 */
const NoNetwork: React.FC = (): JSX.Element => {
  // i18n
  const { t } = useTranslation();

  // METHODS

  /**
   * "Manejador" del botón de "comprobar conexión"
   */
  function handleClickButtonCheckNetwork(): void {
    window.location.reload();
  }

  return (
    <div className="flex flex-col items-center justify-start p-4">
      {/* Icon */}
      <WifiIcon className="h-24 w-24 text-primary mb-2" aria-hidden="true" />
      {/* Text */}
      <p className="text-lg text-gray-900 font-medium mb-2">
        {t("app.network.title")}
      </p>
      <p className="text-sm text-gray-500 font-medium mb-8">
        {t("app.network.text")}
      </p>
      <Button
        type="button"
        label={t("app.network.button")}
        variant="default"
        onClick={() => handleClickButtonCheckNetwork()}
      />
    </div>
  );
};

const AppNetwork: React.FC = (): null => {
  // Actions
  const modalActions = useActions(ModalActions);
  const notificationActions = useActions(NotificationActions);

  // EVENTS

  useEffect(() => {
    handleNetworkEvents().catch((err: any) => handleError(err.message));

    return () => {
      Network.removeAllListeners().catch((err: any) =>
        handleError(err.message),
      );
    };
  }, []);

  // METHODS

  /**
   * Handle error
   */
  function handleError(error: string): void {
    notificationActions.setNotification({
      show: true,
      text: error,
    });
  }

  /**
   * Manejador eventos del network
   */
  async function handleNetworkEvents(): Promise<void> {
    // Eventos de cambios de estado en la conexión
    await Network.addListener("networkStatusChange", handleCheckConnection);
  }

  /**
   * Manejador en los cambios de conexión (internet)
   */
  function handleCheckConnection(status: object): void {
    const connected = get(status, "connected", false);
    if (!connected) {
      // Mostramos modal de conexión
      modalActions.setModal({
        hideHeader: true,
        hideFooter: true,
        persistent: true,
        show: true,
        content: NoNetwork,
      });
    }
  }

  return null;
};

export default AppNetwork;
