// Route
import { Navigate } from "react-router-dom";

// Propiedas del componente
export interface ProtectedRouteProps {
  isAllowed: boolean;
  redirect: string;
  children: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed = true,
  redirect = "/",
  children = null,
}: ProtectedRouteProps): JSX.Element => {
  return !isAllowed || children === null ? (
    <Navigate to={redirect} replace />
  ) : (
    children
  );
};

export default ProtectedRoute;
