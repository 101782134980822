import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

// Propiedas del componente
export interface NotificationProps {
  show: boolean;
  text: String;
}

const Notification: React.FC<NotificationProps> = ({
  show = false,
  text = "Contenido de la notificación",
}: NotificationProps): JSX.Element => {
  // DATA

  // Se muestra/oculta la notificación
  const [currentShow, setCurrentShow] = useState(show);

  // EVENTS

  /**
   * Modificamos el estado del "show"
   */
  useEffect(() => {
    setCurrentShow(show);
  }, [show]);

  // METHODS

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-40 flex items-end px-4 py-6"
    >
      <div className="flex w-full flex-col space-y-4 items-end">
        <Transition
          show={currentShow}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-2"
        >
          <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-neutral-800/[.90] drop-shadow-sm">
            <div className="p-3">
              <p className="text-sm text-white">{text}</p>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
