// constants
import { REDUX_KEY } from "../constants";
// Libs
import { isNil } from "lodash";
// redux
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
// redux-persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
// reducers
import rootReducer from "./root-reducers";
import type { RootState } from "./root-reducers";
// saga
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

// To create different "store" (to testing mainly)
export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
  options?: any,
): EnhancedStore => {
  // Saga
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    // Reducers
    reducer: persistReducer({ key: REDUX_KEY, storage }, rootReducer),
    // Saga and other middlewares
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    preloadedState,
    // Other options
    ...(!isNil(options) &&
    typeof options === "object" &&
    Object.keys(options).length > 0
      ? options
      : {}),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export type AppStore = ReturnType<typeof setupStore>;
