export enum StoreLoadingTypes {
  RESET_LOADING = "RESET_LOADING",
  SET_LOADING = "SET_LOADING",
}

export interface StoreLoadingActions {
  type: StoreLoadingTypes;
  payload?: StoreLoading;
}

export interface StoreLoading {
  show: boolean;
  text?: string;
}

export const initialStoreLoading: StoreLoading = {
  show: false,
  text: "Loading...",
};
