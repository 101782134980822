// React
import React, { useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// Store
import { StoreState } from "../../store/types";
import * as NotificationActions from "../../store/notification/actions";
// Hooks
import useActions from "../../hooks/UseActions";
// Components
import Notification from "../ui/Notification";

const StoreNotification: React.FC = (): JSX.Element => {
  // State
  const notification = useSelector((state: StoreState) => state.notification);
  // Actions
  const notificationActions = useActions(NotificationActions);

  // EVENTS

  useEffect(() => {
    // La notificación es ocultada pasados 3 segundos
    if (notification.show) {
      setTimeout(() => {
        hideNotification();
      }, 3000);
    }
  }, [notification.show]);

  // METHODS

  /**
   * Ocultamos capa de notificación
   */
  function hideNotification(): void {
    // Ocultamos notificación
    notificationActions.setNotification({ show: false });
    // Retraso N segundos para que no se vea el cambio
    // en el efecto CSS de ocultación
    setTimeout(() => {
      notificationActions.resetNotification();
    }, 500);
  }

  return <Notification show={notification.show} text={notification.text} />;
};

export default StoreNotification;
