import { combineReducers } from "redux";
import user from "./user/reducers";
import loading from "./loading/reducers";
import modal from "./modal/reducers";
import notification from "./notification/reducers";
import refreshToken from "./refreshToken/reducers";

const rootReducer = combineReducers({
  user,
  notification,
  loading,
  modal,
  refreshToken,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
