// React
import React from "react";
// Redux
import { useSelector } from "react-redux";
// Store Types
import { StoreState } from "../../store/types";
// Components
import LoadingLayer from "../ui/LoadingLayer";

const StoreLoading: React.FC = (): JSX.Element => {
  // Store
  const loading = useSelector((state: StoreState) => state.loading);

  return <LoadingLayer show={loading.show} text={loading.text} />;
};

export default StoreLoading;
