import React from "react";
import { setClassNames } from "../../../modules/Shared/utils";
import { get, isNil } from "lodash";

// Propiedas del componente
export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  ref?: any;
  size?: string;
  type?: "submit" | "button";
  variant?: "primary" | "secondary" | "default" | "delete";
}

const Button: React.FC<ButtonProps> = ({
  className = "",
  disabled = false,
  loading = false,
  label = "Button",
  onClick,
  ref,
  size = "md",
  type = "button",
  variant = "default",
}: ButtonProps): JSX.Element => {
  // METHODS

  /**
   * Manejador del click del botón
   */
  function handleClick(event: React.SyntheticEvent): void {
    if (!isNil(onClick)) {
      onClick(event);
    }
  }

  /**
   * Clases css del componente
   */
  function buttonClassName(className: string): string {
    const defaultClasses = setClassNames(
      "flex flex-row space-x-1 items-center inline-flex justify-center rounded-lg border drop-shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
      className,
    );
    const sizeClasses = {
      lg: "text-base py-2 px-4",
      md: "text-sm py-2 px-4",
      sm: "text-sm py-2 px-3 leading-4",
    };
    const variantClasses = {
      primary:
        "border-transparent bg-primary text-white hover:bg-primary-light focus:ring-primary",
      secondary:
        "border-transparent bg-secondary text-white hover:bg-secondary-light focus:ring-secondary",
      delete:
        "border-transparent bg-red-500 text-white hover:bg-red-300 focus:ring-red-500",
    };

    const currentSizeClasses = get(sizeClasses, size, "text-sm py-2 px-4");
    const currentVariantClasses = get(
      variantClasses,
      variant,
      "border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-light",
    );

    return setClassNames(
      defaultClasses,
      currentSizeClasses,
      currentVariantClasses,
      disabled ? "opacity-50 cursor-not-allowed pointer-events-none" : "",
    );
  }

  return (
    <button
      disabled={disabled}
      className={buttonClassName(className)}
      onClick={(event: React.SyntheticEvent) => handleClick(event)}
      ref={ref}
      type={type}
    >
      <span>{label}</span>
      {/* Loading */}
      {loading ? (
        <svg
          className="h-5 w-5 animate-spin text-white"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : null}
    </button>
  );
};

export default Button;
