import { v4 as uuidv4 } from "uuid";
import { capitalize } from "lodash";
import User from "../domain/User";
import Provider from "../../Providers/domain/Provider";
import AuthRepository from "../domain/AuthRepository";
import Http, {
  HttpOptions,
  HttpMethod,
} from "../../Shared/infrastructure/Http";

export default class ApiAuthRepository extends Http implements AuthRepository {
  public apiUrl: string = "/";

  constructor(props = { apiUrl: "/" }) {
    super();
    this.apiUrl = props.apiUrl;
  }

  /**
   * Find user by email
   */
  async findUserByEmail(
    email: string,
    options: Partial<HttpOptions>,
  ): Promise<User> {
    const user = await this.request(`${this.apiUrl}/auth/email`, {
      method: HttpMethod.POST,
      body: {
        email,
      },
      ...options,
    });

    return user;
  }

  /**
   * Get all providers by user_id
   */
  async getAllProvidersById(
    id: string,
    options: Partial<HttpOptions>,
  ): Promise<Provider[]> {
    const providers: Provider[] = await this.request(
      `${this.apiUrl}/auth/${id}/providers`,
      {
        method: HttpMethod.GET,
        ...options,
      },
    );

    return providers.map((provider) => {
      return { ...provider, label: capitalize(provider.type) };
    });
  }

  /**
   * Login user
   */
  async loginAuth(
    email: string,
    password: string,
    options: Partial<HttpOptions>,
  ): Promise<User> {
    const user = await this.request(`${this.apiUrl}/auth/login`, {
      method: HttpMethod.POST,
      body: {
        email,
        password,
      },
      ...options,
    });

    return user;
  }

  /**
   * Logout user
   */
  async logoutAuth(
    refreshToken: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/auth/logout/${refreshToken}`, {
      method: HttpMethod.DELETE,
      ...options,
    });
  }

  /**
   * Recover user password
   */
  async recoverPasswordAuth(
    email: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/auth/recover-password`, {
      method: HttpMethod.POST,
      body: { email },
      ...options,
    });
  }

  /**
   * Refresh token
   */
  async refreshTokenAuth(
    refreshToken: string,
    options: Partial<HttpOptions>,
  ): Promise<string> {
    const token = await this.request(
      `${this.apiUrl}/auth/refresh-token/${refreshToken}`,
      { method: HttpMethod.GET, ...options },
    );

    return token;
  }

  /**
   * Register user
   */
  async registerAuth(
    email: string,
    password: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/auth/register`, {
      method: HttpMethod.POST,
      body: {
        id: uuidv4(),
        email,
        password,
      },
      ...options,
    });
  }

  /**
   * Reset user password
   */
  async resetPasswordAuth(
    token: string,
    password: string,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/auth/reset-password`, {
      method: HttpMethod.PATCH,
      body: { token, password },
      ...options,
    });
  }

  /**
   * Update user
   */
  async updateUser(
    data: Partial<User>,
    options: Partial<HttpOptions>,
  ): Promise<void> {
    await this.request(`${this.apiUrl}/auth`, {
      method: HttpMethod.PATCH,
      body: data,
      ...options,
    });
  }
}
