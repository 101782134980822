import { isNil, merge } from "lodash";
import { isJsonString } from "../utils";

export enum HttpMethod {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export enum HttpMode {
  CORS = "cors",
  NO_CORS = "no-cors",
  SAME_ORIGIN = "same-origin",
}

export enum HttpCredentials {
  INCLUDE = "include",
  OMIT = "omit",
  SAME_ORIGIN = "same-origin",
}

export enum HttpCache {
  DEFAULT = "default",
  FORCE_CACHE = "force-cache",
  NO_STORE = "no-store",
  RELOAD = "reload",
  ONLY_IF_CACHED = "only-if-cached",
}

export enum HttpRedirect {
  FOLLOW = "follow",
  ERROR = "error",
  MANUAL = "manuel",
}

export enum HttpReferrerPolicy {
  NO_REFERRER = "no-referrer",
  NO_REFERRER_WHEN_DOWNGRADE = "no-referrer-when-downgrade",
  SAME_ORIGIN = "same-origin",
  ORIGIN = "origin",
  STRICT_ORIGIN = "strict-origin",
  ORIGIN_WHEN_CROSS_ORIGIN = "origin-when-cross-origin",
  STRICT_ORIGIN_WHEN_CROSS_ORIGIN = "strict-origin-when-cross-origin",
  UNSAFE_URL = "unsafe-url",
}

export interface HttpOptions {
  method: HttpMethod;
  headers: Headers | Record<string, string>;
  body: any;
  mode: HttpMode;
  credentials: HttpCredentials;
  cache: HttpCache;
  redirect: HttpRedirect;
  referrer: string;
  referrerPolicy: HttpReferrerPolicy;
  integrity: string;
  keepalive: any;
  signal: AbortSignal;
  timeout: number;
}

export default class Http {
  /**
   * Set the timeout controller from request
   */
  private setTimeoutController(miliseconds = 15000): [any, any] {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), miliseconds);

    return [abortController, timeoutId];
  }

  /**
   * Request any type
   */
  async request(url: string, options: Partial<HttpOptions>): Promise<any> {
    const { timeout = 15000, ...otherOptions } = options;
    // Remove undefined options
    const definedOptions = Object.entries(otherOptions).reduce(
      (accOptions: Record<string, any>, option) => {
        const [key, value] = option;

        if (!isNil(value)) {
          // Body needs to be parsed
          accOptions[key] = key === "body" ? JSON.stringify(value) : value;
        }

        return accOptions;
      },
      {},
    );
    // Abort signal
    const [abortController, timeoutId] = this.setTimeoutController(timeout);

    try {
      if (isNil(url)) {
        throw Error("URL is not valid");
      }

      const response = await fetch(
        url,
        merge(
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: HttpMethod.GET,
            signal: abortController.signal,
          },
          definedOptions,
        ),
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseString = await response.text();

      if (isJsonString(responseString)) {
        return JSON.parse(responseString);
      }

      return isNil(responseString) || responseString === ""
        ? null
        : responseString;
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(err.message);
      }
    } finally {
      clearTimeout(timeoutId);
    }
  }
}
