// Awilix
import { createContainer, asClass, InjectionMode, asValue } from "awilix";
// Config
import { API_URL } from "./config";
// Modules
import ApiAuthRepository from "../Auth/infraestructure/ApiAuthRepository";
import ApiProviderRepository from "../Providers/infraestructure/ApiProviderRepository";
import SpotifyProvider from "./infrastructure/SpotifyProvider";
import YoutubeProvider from "./infrastructure/YoutubeProvider";

// container
const container = createContainer({
  injectionMode: InjectionMode.PROXY,
}).register({
  apiUrl: asValue(API_URL),
  authRepository: asClass(ApiAuthRepository).singleton(),
  providerRepository: asClass(ApiProviderRepository).singleton(),
  spotifyProvider: asClass(SpotifyProvider).singleton(),
  youtubeProvider: asClass(YoutubeProvider).singleton(),
});

export default container;
