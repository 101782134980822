import {
  initialStoreNotification,
  StoreNotification,
  StoreNotificationTypes,
  StoreNotificationActions,
} from "./types";

const storeNotificationReducers = (
  state: StoreNotification = initialStoreNotification,
  action: StoreNotificationActions,
): StoreNotification => {
  switch (action.type) {
    case StoreNotificationTypes.RESET_NOTIFICATION: {
      return { ...initialStoreNotification };
    }
    case StoreNotificationTypes.SET_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default storeNotificationReducers;
