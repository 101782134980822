export enum StoreNotificationTypes {
  RESET_NOTIFICATION = "RESET_NOTIFICATION",
  SET_NOTIFICATION = "SET_NOTIFICATION",
}

export interface StoreNotificationActions {
  type: StoreNotificationTypes;
  payload?: StoreNotification;
}

export interface StoreNotification {
  show: boolean;
  text: string;
}

export const initialStoreNotification: StoreNotification = {
  show: false,
  text: "Notification content...",
};
