// API SERVER
export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.putsong.limtrack.com"
    : "http://localhost:4000";
// APP URL
export const APP_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.putsong.limtrack.com"
    : "http://localhost:3000";
// ANDROID PROTOCOL
export const ANDROID_PROTOCOL = "com.limtrack.putsong";
// PROVIDERS
export const SPOTIFY_CLIENT_ID = "8220d4b1e2564278b7934975d97e04b9";
export const YOUTUBE_CLIENT_ID =
  "929215396731-4bnd926ip5t27dqmg3fuv5jk62u4f92q.apps.googleusercontent.com";
