import {
  initialStoreLoading,
  StoreLoading,
  StoreLoadingTypes,
  StoreLoadingActions,
} from "./types";

const storeLoadingReducers = (
  state: StoreLoading = initialStoreLoading,
  action: StoreLoadingActions,
): StoreLoading => {
  switch (action.type) {
    case StoreLoadingTypes.RESET_LOADING: {
      return { ...initialStoreLoading };
    }
    case StoreLoadingTypes.SET_LOADING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default storeLoadingReducers;
