// Constants
import { USER_AGENTS } from "../../../app/constants";
import { APP_URL } from "../../../modules/Shared/config";
// QR frames
import frameQrEn from "../assets/frame-qr_en.png";
import frameQrEs from "../assets/frame-qr_es.png";
// Libs
import qrcode from "qrcode";
import { get, isNil } from "lodash";

/**
 * Creamos QR a partir de la URL de la cola de reproducción del proveedor
 */
export async function createPlaybackQueueQR(
  id: string,
  options = { width: 1000 },
): Promise<string> {
  // Parte de la URL a codificar
  const appUrl: string = APP_URL;
  // Marcos disponibles
  const framesQr = {
    en: frameQrEn,
    es: frameQrEs,
  };
  // Idioma del navegador
  const navigatorLanguage = getNavigatorLanguage().includes("-")
    ? getNavigatorLanguage().split("-")
    : getNavigatorLanguage();
  const currentNavigatorLanguage = Array.isArray(navigatorLanguage)
    ? navigatorLanguage[0]
    : navigatorLanguage;
  // Marco Qr según idioma del navegador
  const currentFrameQr = get(framesQr, currentNavigatorLanguage, frameQrEn);
  // Generamos QR
  const qr: string = await qrcode.toDataURL(
    `${appUrl}/providers/playback-queue/${id}`,
    options,
  );

  // Montamos canvas para incluir marco y QR
  const canvasElement = document.createElement("CANVAS") as HTMLCanvasElement;
  // Cargamos las imágenes que comprondrán el QR
  const [currentFrameQrLoaded, qrLoaded] = await Promise.all(
    [currentFrameQr, qr].map(async (src) => {
      return await new Promise((resolve) => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
          resolve(image);
        };
      });
    }),
  );

  // Tamaño del canvas (tamaño del Frame)
  canvasElement.width = 1100;
  canvasElement.height = 1443;
  // Contexto del canvas
  const canvasContext = canvasElement.getContext("2d");
  if (!isNil(canvasContext)) {
    // Montamos imágenes en el QR
    canvasContext.drawImage(currentFrameQrLoaded as HTMLImageElement, 0, 0);
    canvasContext.drawImage(qrLoaded as HTMLImageElement, 50, 50);
  }

  return canvasElement.toDataURL();
}

/**
 * Check string is a JSON
 */
export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Agrupa clases CSS para montar los atributos "classNames"
 */
export function setClassNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

/**
 * Obtiene idioma del navegador
 */
export function getNavigatorLanguage(): string {
  return navigator.languages?.length > 0 ? navigator.languages[0] : "en";
}

/**
 * Obtiene "userAgent" del navegador
 */
export function getNavigatorUserAgent(): string {
  const userAgents = Object.values(USER_AGENTS);
  const navigatorUserAgent = navigator.userAgent;
  let currentUserAgent = "other";

  userAgents.forEach((userAgent: string) => {
    const regex = new RegExp(userAgent, "i");

    if (regex.test(navigatorUserAgent)) {
      currentUserAgent = userAgent;
    }
  });

  return currentUserAgent;
}

/**
 * Une cadenas separadas por un caracter o devuelve null
 */
export function joinStrings(
  elements: string[],
  char: string = ",",
): string | null {
  if (!Array.isArray(elements)) return null;
  if (typeof char !== "string") return null;

  const everyElements = elements.reduce<string[]>((accString, s: string) => {
    if (!isNil(s) && typeof s === "string") {
      accString.push(s);
    }

    return accString;
  }, []);

  return everyElements.length === 0 ? null : everyElements.join(char);
}

/**
 * Cargamos una etiqueta "script" asíncronamente
 */
export async function loadScriptTag(
  id: string,
  src: string,
  options = { type: "text/javascript", async: true, defer: false },
): Promise<void> {
  return await new Promise((resolve, reject) => {
    if (isNil(id) || isNil(src)) {
      reject(new Error('"id" and "src" can\'t be NULL'));
    }

    try {
      const currentScript = document.querySelector(`script[id="${id}"]`);

      if (!isNil(currentScript)) resolve();

      const script = document.createElement("script");

      Object.entries({
        ...options,
        id,
        src,
      }).forEach((option) => {
        const [key, value] = option;
        script.setAttribute(key, value.toString());
      });

      script.onload = async () => {
        // Hack tp avoid some errors in load to libs
        await timeout(500);
        resolve();
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    } catch (error: any) {
      reject(new Error(error.message));
    }
  });
}

/**
 * Combinación de setTimeout con "Promise" para conseguir
 * que las ejecuciones se paren "N" segundos
 *
 */
export async function timeout(ms: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}
