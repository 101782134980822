import {
  initialStoreModal,
  StoreModal,
  StoreModalTypes,
  StoreModalActions,
} from "./types";

const storeModalReducers = (
  state: StoreModal = initialStoreModal,
  action: StoreModalActions,
): StoreModal => {
  switch (action.type) {
    case StoreModalTypes.RESET_MODAL: {
      return { ...initialStoreModal };
    }
    case StoreModalTypes.SET_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default storeModalReducers;
