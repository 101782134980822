// Libs
import { isNil } from "lodash";
// Redux - types
import { StoreModalTypes } from "../modal/types";
import { StoreNotificationTypes } from "../notification/types";
import { StoreRefreshTokenTypes } from "../refreshToken/types";
import { StoreUserTypes } from "../user/types";
// Components
import AppError from "../../components/app/AppError";
// Contenedor dependencias
import container from "../../../modules/Shared/container";
// Selectores
import { selectUserToken } from "../selectors";
// Saga
import { select, put, takeEvery, takeLatest } from "redux-saga/effects";

/**
 * Logamos usuario en servidor
 */
function* loginUserInServer({ payload }: any): any {
  try {
    // Payload data
    const { email, password } = payload;
    // Api
    const authRepository = container.resolve("authRepository");
    // User
    const loggedUser = yield authRepository.loginAuth(email, password);
    // Reset user in store
    yield put({ type: StoreUserTypes.RESET_USER });
    // Set user in store
    yield put({
      type: StoreUserTypes.SET_USER,
      payload: loggedUser,
    });
  } catch (err: any) {
    // Show error
    yield put({
      type: StoreNotificationTypes.SET_NOTIFICATION,
      payload: {
        show: true,
        text: err.message,
      },
    });
  }
}

/**
 * Des-Logamos usuario en servidor
 */
function* logoutUserInServer({ payload }: any): any {
  try {
    // user token
    const userToken = yield select(selectUserToken);
    // Payload data
    const { refreshToken } = payload;
    // Api
    const authRepository = container.resolve("authRepository");
    // Logout from server (remove refresh token)
    yield authRepository.logoutAuth(refreshToken, {
      headers: {
        Authorization: `Bearer ${userToken as string}`,
      },
    });
  } finally {
    // always reset the user even if logout fails
    yield put({ type: StoreUserTypes.RESET_USER });
  }
}

/**
 * Refrescamos el token del usuario
 */
function* refreshTokenInServer({ payload }: any): any {
  try {
    // Loading refresh token
    yield put({
      type: StoreRefreshTokenTypes.SET_REFRESHTOKEN,
      payload: {
        loading: true,
      },
    });

    // Payload data
    const { refreshToken } = payload;
    // Api
    const authRepository = container.resolve("authRepository");
    // Get new token in server
    const token = yield authRepository.refreshTokenAuth(refreshToken);

    // Change state in store
    yield put({
      type: StoreUserTypes.SET_USER,
      payload: { token },
    });
  } catch (err: any) {
    // Show error
    yield put({
      type: StoreModalTypes.SET_MODAL,
      payload: {
        hideFooter: true,
        hideHeader: true,
        persistent: true,
        show: true,
        content: AppError,
        contentProps: { error: err.message },
      },
    });
  } finally {
    // Loading refresh token
    yield put({
      type: StoreRefreshTokenTypes.SET_REFRESHTOKEN,
      payload: {
        loading: false,
      },
    });
  }
}

/**
 * Actualizamos datos del usuario en el servidor API
 */
function* setUserInServer({ payload }: any): any {
  try {
    // user token
    const userToken = yield select(selectUserToken);
    // Payload data
    const { password, ...params } = payload;
    // Api
    const authRepository = container.resolve("authRepository");

    // Save in server
    yield authRepository.updateUser(
      {
        ...params,
        ...(!isNil(password) ? { password } : {}),
      },
      {
        headers: {
          Authorization: `Bearer ${userToken as string}`,
        },
      },
    );
    // Change state in store
    yield put({
      type: StoreUserTypes.SET_USER,
      payload: params,
    });
  } catch (err: any) {
    // Show error
    yield put({
      type: StoreNotificationTypes.SET_NOTIFICATION,
      payload: {
        show: true,
        text: err.message,
      },
    });
  }
}

// Watcher functions
export function* loginUserInServerTakeLatest(): any {
  yield takeLatest(StoreUserTypes.LOGIN_USER_IN_SERVER, loginUserInServer);
}

export function* logoutUserInServerTakeLatest(): any {
  yield takeLatest(StoreUserTypes.LOGOUT_USER_IN_SERVER, logoutUserInServer);
}

export function* refreshTokenInServerTakeEvery(): any {
  yield takeEvery(StoreUserTypes.REFRESH_TOKEN_IN_SERVER, refreshTokenInServer);
}

export function* setUserInServerTakeLatest(): any {
  yield takeLatest(StoreUserTypes.SET_USER_IN_SERVER, setUserInServer);
}
