import React from "react";
import Loading, { LoadingProps } from "./Loading";

const LoadingContent: React.FC<LoadingProps> = ({
  border = 2,
  color = "primary",
  size = 10,
  text,
}: LoadingProps): JSX.Element => {
  return (
    <div className="relative block w-full rounded-lg p-12 text-center">
      <Loading border={border} color={color} size={size} text={text} />
    </div>
  );
};

export default LoadingContent;
