import React, { useState, useEffect } from "react";
import Loading, { LoadingProps } from "./Loading";
import { Transition } from "@headlessui/react";

// Propiedas del componente
export type LoadingLayerProps = LoadingProps & { show: boolean };

const LoadingLayer: React.FC<LoadingLayerProps> = ({
  border = 2,
  color = "primary",
  size = 10,
  text = "Loading",
  show = false,
}: LoadingLayerProps): JSX.Element => {
  // DATA

  const [currentShow, setCurrentShow] = useState(show);

  /**
   * Modificamos el estado del "show"
   */
  useEffect(() => {
    setCurrentShow(show);
  }, [show]);

  return (
    <Transition
      show={currentShow}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-100 opacity-75">
        <Loading border={border} color={color} size={size} text={text} />
      </div>
    </Transition>
  );
};

export default LoadingLayer;
