// React
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Messages
import resources from "./resources";
// Utils
import { getNavigatorLanguage } from "../../modules/Shared/utils";

// To avoid this error --> https://github.com/i18next/i18next/issues/1874
// declare custom type options so the return is always a string.
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// Idioma del navegador
const navigatorLanguage = getNavigatorLanguage().includes("-")
  ? getNavigatorLanguage().split("-")
  : getNavigatorLanguage();

// Load i18n
i18n
  .use(initReactI18next)
  .init({
    // Selected language
    lng: Array.isArray(navigatorLanguage)
      ? navigatorLanguage[0]
      : navigatorLanguage,
    // debug: true,
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  })
  .catch((err: any) => {
    console.log(err.message);
  });

export default i18n;
