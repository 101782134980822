import { StoreModal, StoreModalActions, StoreModalTypes } from "./types";

/**
 * Reset store's modal
 */
export function resetModal(): StoreModalActions {
  return {
    type: StoreModalTypes.RESET_MODAL,
  };
}

/**
 * Set parameters in store's modal
 */
export function setModal(payload: StoreModal): StoreModalActions {
  return {
    type: StoreModalTypes.SET_MODAL,
    payload,
  };
}
