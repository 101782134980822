import { initialStoreUser, StoreUser } from "./user/types";
import { initialStoreLoading, StoreLoading } from "./loading/types";
import {
  initialStoreNotification,
  StoreNotification,
} from "./notification/types";
import { initialStoreModal, StoreModal } from "./modal/types";
import {
  initialStoreRefreshToken,
  StoreRefreshToken,
} from "./refreshToken/types";

export interface StoreState {
  loading: StoreLoading;
  modal: StoreModal;
  notification: StoreNotification;
  refreshToken: StoreRefreshToken;
  user: StoreUser;
}

export const initialStore: StoreState = {
  loading: initialStoreLoading,
  modal: initialStoreModal,
  notification: initialStoreNotification,
  refreshToken: initialStoreRefreshToken,
  user: initialStoreUser,
};
