// React
import { useMemo } from "react";
// Redux
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const useActions = (actions: any, deps?: any): any => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    Array.isArray(deps) ? [dispatch, ...deps] : deps,
  );
};

export default useActions;
