import { APP_URL, YOUTUBE_CLIENT_ID } from "../../Shared/config";

export interface GetAuthorizeUrlParams {
  access_type: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
}

export default class YoutubeProvider {
  private readonly clientId: string = YOUTUBE_CLIENT_ID;

  /**
   * Get authorize from provider
   */
  getAuthorizeUrl(params?: GetAuthorizeUrlParams): string {
    const defaultParams = {
      access_type: "offline",
      client_id: this.clientId,
      redirect_uri: `${APP_URL}/youtube-callback`,
      response_type: "code",
      scope: "https://www.googleapis.com/auth/youtube.readonly",
    };

    const urlParams = new URLSearchParams({ ...defaultParams, ...params });

    return `https://accounts.google.com/o/oauth2/v2/auth?${urlParams.toString()}`;
  }
}
