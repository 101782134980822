import React from "react";
import Button from "./Button";
import { isNil } from "lodash";

// Propiedas del componente
export interface FormButtonsProps {
  acceptButtonClassName?: string;
  acceptButtonDisabled?: boolean;
  hideAcceptButton?: boolean;
  acceptButtonLoading?: boolean;
  acceptButtonLabel?: string;
  acceptButtonClick?: (event: React.SyntheticEvent) => void;
  acceptButtonRef?: any | null;
  acceptButtonType?: "submit" | "button";
  cancelButtonClassName?: string;
  cancelButtonDisabled?: boolean;
  cancelButtonHide?: boolean;
  cancelButtonLabel?: string;
  cancelButtonClick?: (event: React.SyntheticEvent) => void;
  cancelButtonRef?: any | null;
  cancelButtonType?: "submit" | "button";
}

const FormButtons: React.FC<FormButtonsProps> = ({
  acceptButtonClassName = "",
  acceptButtonDisabled = false,
  hideAcceptButton = false,
  acceptButtonLoading = false,
  acceptButtonLabel = "Aceptar",
  acceptButtonClick,
  acceptButtonRef = null,
  acceptButtonType = "submit",
  cancelButtonClassName = "",
  cancelButtonDisabled = false,
  cancelButtonHide = false,
  cancelButtonLabel = "Cancelar",
  cancelButtonClick,
  cancelButtonRef = null,
  cancelButtonType = "button",
}: FormButtonsProps): JSX.Element => {
  // METHODS

  /**
   * Manejador del click del botón aceptar
   */
  function handleAcceptButtonOnClick(event: React.SyntheticEvent): void {
    if (!isNil(acceptButtonClick)) {
      acceptButtonClick(event);
    }
  }

  /**
   * Manejador del click del botón aceptar
   */
  function handleCancelButtonOnClick(event: React.SyntheticEvent): void {
    if (!isNil(cancelButtonClick)) {
      cancelButtonClick(event);
    }
  }

  return (
    <div className="space-x-3">
      {/* Cancel button */}
      {!cancelButtonHide ? (
        <Button
          className={cancelButtonClassName}
          disabled={cancelButtonDisabled || acceptButtonLoading}
          label={cancelButtonLabel}
          onClick={(event: React.SyntheticEvent) =>
            handleCancelButtonOnClick(event)
          }
          type={cancelButtonType}
          ref={cancelButtonRef}
        />
      ) : null}
      {/* Accept button */}
      {!hideAcceptButton ? (
        <Button
          className={acceptButtonClassName}
          disabled={acceptButtonDisabled || acceptButtonLoading}
          label={acceptButtonLabel}
          loading={acceptButtonLoading}
          onClick={(event: React.SyntheticEvent) =>
            handleAcceptButtonOnClick(event)
          }
          ref={acceptButtonRef}
          type={acceptButtonType}
          variant="primary"
        />
      ) : null}
    </div>
  );
};

export default FormButtons;
